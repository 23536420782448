.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
    background-color: #e96565 !important;
  }
  
  .pretty input:checked ~ .state.p-success-o label:before,
  .pretty.p-toggle .state.p-success-o label:before {
    border-color: #e96565;
  }
  
  .react-datepicker__header {
    background-color: #e96565 !important;
  
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: white;
  }
  
  div.react-datepicker__month-container
    > div.react-datepicker__header
    > div.react-datepicker__day-names
    > .react-datepicker__day-name {
    color: white;
  }
  
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #e96565 !important;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #e96565 !important;
  }
  
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 20px;
  }
  