.select-light {
  --styled-select__color: white;
  --styled-select__background-color: transparent;
  --styled-select__border-color: white;
  --styled-select__border-width: 1px;
  --styled-select__border-radius: 0;

  --styled-select-placeholder__color: white;
  --styled-select-placeholder__font-family: Avenir, Helvetica, Arial, sans-serif;
  --styled-select-placeholder__font-size: 18px;
  --styled-select-placeholder__padding: 0px 10px;

  --styled-select-arrow-zone__width: 25px;
  --styled-select-arrow__color: white;
  --styled-select-arrow__size: 8;

  --styled-select-control__border-color: white;
  --styled-select-control__border-color--focused: white;

  --styled-select-menu-outer__background-color: rgba(0, 0, 0, 0.4);
  --styled-select-menu-outer__border-color: white;

  --styled-select-option__background-color: rgba(0, 0, 0, 0.4);
  --styled-select-option__background-color--focused: rgba(0, 0, 0, 0.4);
  --styled-select-option__background-color--selected: rgba(0, 0, 0, 0.4);
  --styled-select-option__color: white;
  --styled-select-option__color--focused: white;
  --styled-select-option__color--selected: white;

  --styled-select-value__font-size: 16px;
}

.select-dark {
  --styled-select__color: black;
  --styled-select__background-color: transparent;
  --styled-select__border-color: black;
  --styled-select__border-width: 1px;
  --styled-select__border-radius: 0;

  --styled-select-placeholder__color: black;
  --styled-select-placeholder__font-family: Avenir, Helvetica, Arial, sans-serif;
  --styled-select-placeholder__font-size: 18px;
  --styled-select-placeholder__padding: 0px 10px;

  --styled-select-arrow-zone__width: 25px;
  --styled-select-arrow__color: black;
  --styled-select-arrow__size: 8;

  --styled-select-control__border-color: black;
  --styled-select-control__border-color--focused: black;

  --styled-select-menu-outer__background-color: rgba(0, 0, 0, 0.4);
  --styled-select-menu-outer__border-color: black;

  --styled-select-option__background-color: rgba(0, 0, 0, 0.4);
  --styled-select-option__background-color--focused: rgba(0, 0, 0, 0.4);
  --styled-select-option__background-color--selected: rgba(0, 0, 0, 0.4);
  --styled-select-option__color: white;
  --styled-select-option__color--focused: white;
  --styled-select-option__color--selected: white;

  --styled-select-value__font-size: 16px;
}
