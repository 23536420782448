.pretty {
    margin-right: 0.3rem;
  }
  
  .pretty input:checked ~ .state.p-danger-o label:before,
  .pretty.p-toggle .state.p-danger-o label:before {
    border-color: #e96565;
  }
  
  .pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
    background-color: #e96565 !important;
  }
  