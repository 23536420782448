.mobile-menu-enter {
  opacity: 0.01;

}

.mobile-menu-enter.mobile-menu-enter-active {
  opacity: 1;

  transition: all 0.3s ease-in;
}

.mobile-menu-leave {
  opacity: 1;
}

.mobile-menu-leave.mobile-menu-leave-active {
  opacity: 0.01;
  transition: all 0.3s ease-in;
}

.mobile-menu-appear {
  opacity: 0.01;
}

.mobile-menu-appear.mobile-menu-appear-active {
  opacity: 1;
  transition: all 0.3s ease-in;
}