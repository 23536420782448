[badge]:after {
    background: #e96565;
    border-radius: 30px;
    color: #fff;
    content: attr(badge);
    font-size: 11px;
    margin-top: -10px;
    margin-left: -10px;
    min-width: 15px;
    padding: 2px;
    position: absolute;
    text-align: center;
  }
  
  [badge^="-"]:after,
  [badge="0"]:after,
  [badge=""]:after {
    display: none;
  }